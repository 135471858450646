import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Spinner from './components/spinner/spinner';
import { authRoutes, sidebarRoutes } from './commons/types/routes';

const AuthenticationLayout = lazy(() => import('./layout/authentication/authentication_layout'));
const Login = lazy(() => import('./pages/authentication'));
const OTP = lazy(() => import('./pages/authentication/components/otp'));
const PrivatePolicy = lazy(() => import('./pages/privatePolicy/index'));

const MainLayout = lazy(() => import('./layout/dashboard'));
const Dashboard = lazy(() => import('./pages/analytics/dashboard'));
const MapPage = lazy(() => import('./pages/map'));
const Orders = lazy(() => import('./pages/orders/orders'));
const Vendor = lazy(() => import('./pages/vendor/vendor'));
const AddProduct = lazy(() => import('./pages/vendor/components/addProduct'));
const Rider = lazy(() => import('./pages/rider/rider'));
const FleetOwner = lazy(() => import('./pages/fleetOwner/fleet_owner'));
const Adverts = lazy(() => import('./pages/adverts/adverts'));
const Coupon = lazy(() => import('./pages/coupon/coupon'));
const AdminCommission = lazy(() => import('./pages/adminCommission'));
const Accounting = lazy(() => import('./pages/accounting/accounting'));
const GeoFence = lazy(() => import('./pages/geoFence/geofence'));
const SubAdmin = lazy(() => import('./pages/subAdmin/subAdmin'));
const Settings = lazy(() => import('./pages/settings/settings'));
const OrderDetails = lazy(() => import('./pages/orders/components/orderDetails/order_details'));
const VendorDetails = lazy(() => import('./pages/vendor/components/vendorDetails/vendor_details'));
const RiderDetails = lazy(() => import('./pages/rider/components/riderDetails/rider_details'));
const FleetOwnerDetails = lazy(() => import('./pages/fleetOwner/components/fleetOwnerDetails/fleet_owner_details'));
const AddNewCoupon = lazy(() => import('./pages/coupon/components/addNewCoupon/add_new_coupon'));
const EditGeoFence = lazy(() => import('./pages/geoFence/components/edit/edit'));
const AddNew = lazy(() => import('./pages/geoFence/components/addNew/add_new'));
const AddNewCommission = lazy(() => import('./pages/adminCommission/components/addNewCommission/add_new_commission'));
const EditNewCommission = lazy(() => import('./pages/adminCommission/components/editCommission/index'));
const PaymentPage = lazy(() => import('./pages/payment'));

const ErrorPage = lazy(() => import('./pages/error/index'));

function App() {
  return (
    <Suspense fallback={<Spinner message="Building page..." />}>
      <BrowserRouter>
        <Routes>
          <Route path={authRoutes.login} element={<AuthenticationLayout />}>
            <Route index element={<Login />} />
            <Route path={authRoutes.loginOTP} element={<OTP />} />
          </Route>
          <Route path="/private-policy" element={<PrivatePolicy />} />

          <Route path="" element={<MainLayout />}>
            <Route index path={sidebarRoutes.dashboard} element={<Dashboard />} />
            <Route path={sidebarRoutes.map} element={<MapPage />} />
            <Route path={sidebarRoutes.orders} element={<Orders />} />
            <Route path={sidebarRoutes.viewOrder} element={<OrderDetails />} />
            <Route path={sidebarRoutes.vendors} element={<Vendor />} />
            <Route path={sidebarRoutes.viewVendor} element={<VendorDetails />} />
            <Route path={sidebarRoutes.addProduct} element={<AddProduct />} />
            <Route path={sidebarRoutes.riders} element={<Rider />} />
            <Route path={sidebarRoutes.viewRider} element={<RiderDetails />} />
            <Route path={sidebarRoutes.fleetOwners} element={<FleetOwner />} />
            <Route path={sidebarRoutes.viewFleetOwner} element={<FleetOwnerDetails />} />
            <Route path={sidebarRoutes.adverts} element={<Adverts />} />
            <Route path={sidebarRoutes.coupon} element={<Coupon />} />
            <Route path={sidebarRoutes.addCoupon} element={<AddNewCoupon />} />
            <Route path={sidebarRoutes.commission} element={<AdminCommission />} />
            <Route path={sidebarRoutes.addCommission} element={<AddNewCommission />} />
            <Route path={sidebarRoutes.editCommission} element={<EditNewCommission />} />
            <Route path={sidebarRoutes.accounting} element={<Accounting />} />
            <Route path={sidebarRoutes.geoFence} element={<GeoFence />} />
            <Route path={sidebarRoutes.editGeoFence} element={<EditGeoFence />} />
            <Route path={sidebarRoutes.addGeoFence} element={<AddNew />} />
            <Route path={sidebarRoutes.subAdmin} element={<SubAdmin />} />
            <Route path={sidebarRoutes.settings} element={<Settings />} />
            <Route path={sidebarRoutes.payment} element={<PaymentPage />} />
          </Route>

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
