import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetVendorProductRequestPayload, VendorProductsState } from '../vendor_types';
import { HttpStatus } from '../../../commons';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'VENDOR_PRODUCTS';

const initialState: VendorProductsState = {
  status: HttpStatus.IDLE,
  message: undefined,
  products: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getVendorProducts = createAsyncThunk(
  `${NAMESPACE}/get`,
  async (payload: GetVendorProductRequestPayload) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.fetchVendorProduct(payload.vendorId, payload.arg);
    return response;
  }
);

const getVendorProductsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getVendorProducts.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getVendorProducts.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.products = payload.products;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getVendorProducts.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getVendorProductsSlice.reducer;
