import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';
import { GetVendorInventoriesState, GetVendorInventoriesRequestParams } from '../vendor_types';

const NAMESPACE = 'LIST_OF_INVENTORIES';

const initialState: GetVendorInventoriesState = {
  status: HttpStatus.IDLE,
  message: undefined,
  inventories: [],
  paginationCursor: {
    after: '',
    before: '',
    hasNext: false,
    hasPrevious: false,
  },
};

export const getVendorInventories = createAsyncThunk(
  `${NAMESPACE}/get`,
  async (payload: GetVendorInventoriesRequestParams) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.getVendorInventories(payload);
    return response;
  }
);

const getVendorInventoriesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVendorInventories.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getVendorInventories.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.inventories = payload.inventories;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getVendorInventories.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getVendorInventoriesSlice.reducer;
