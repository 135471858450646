import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { UpdateVendorInitialState, UpdateVendorPayload } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'UPDATE_VENDOR';

const initialState: UpdateVendorInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const updateVendor = createAsyncThunk(`${NAMESPACE}/patch`, async (payload: UpdateVendorPayload) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.updateVendor(payload.vendorId, payload.payload);
  return response;
});

export const updateVendorSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetUpdateVendorStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateVendor.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(updateVendor.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.response = payload;
    });
    builder.addCase(updateVendor.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetUpdateVendorStatus } = updateVendorSlice.actions;

export default updateVendorSlice.reducer;
