export enum PAGES {
  request = 'request',
  declinedVendor = 'declinedVendor',
  registeredVendor = 'registeredVendor',
  blockedVendor = 'blockedVendor',
}

export const businessTypePayload = [
  {
    type: 'RESTAURANT',
  },
  {
    type: 'PHARMACY',
  },
  {
    type: 'STORE',
  },
];
