import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { EditCommissionSchemaT } from '../../../schema/commission.schema';
import { Api } from '../../../utils';
import {
  AdminCommissionPayload,
  AdminCommissionResponseObject,
  AdminCommissionResponsePayload,
  CreateAdminCommissionResponsePayload,
  EditAdminCommissionResponsePayload,
  GetAdminCommissionDetailsPayload,
  GetRiderAdminCommissionResponsePayload,
  GetVendorAdminCommissionResponsePayload,
} from '../admin_commission_types';
import { AddEntityCommission } from '../schema/add_entity_commission.schema';

export class AdminCommissionRepository {
  async createAdminCommission(payload: AdminCommissionPayload): Promise<CreateAdminCommissionResponsePayload> {
    const response = await Api.post<CreateAdminCommissionResponsePayload, CreateAdminCommissionResponsePayload>(
      'v1/admin-commission/add',
      payload
    );
    return response;
  }

  async editAdminCommission(payload: EditCommissionSchemaT): Promise<EditAdminCommissionResponsePayload> {
    const response = await Api.put<EditAdminCommissionResponsePayload, EditAdminCommissionResponsePayload>(
      '/v1/admin-commission/edit-one',
      payload
    );
    return response;
  }

  async getAdminCommissionDetails(payload: GetAdminCommissionDetailsPayload): Promise<AdminCommissionResponseObject> {
    const response = await Api.get<AdminCommissionResponseObject, AdminCommissionResponseObject>(
      '/v1/admin-commission/get-one',
      { params: payload }
    );
    return response;
  }

  async getAdminCommission(arg?: PaginationPayload): Promise<AdminCommissionResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<AdminCommissionResponsePayload, AdminCommissionResponsePayload>(
      '/v1/admin-commission',
      {
        params,
      }
    );
    return response;
  }

  async getRiderAdminCommission(): Promise<GetRiderAdminCommissionResponsePayload> {
    const response = await Api.get<GetRiderAdminCommissionResponsePayload, GetRiderAdminCommissionResponsePayload>(
      '/v1/admin-commission/rider-admin-commission'
    );
    return response;
  }

  async getVendorAdminCommission(): Promise<GetVendorAdminCommissionResponsePayload> {
    const response = await Api.get<GetVendorAdminCommissionResponsePayload, GetVendorAdminCommissionResponsePayload>(
      '/v1/admin-commission/vendor-admin-commission'
    );
    return response;
  }

  async addPrivateCommission(payload: AddEntityCommission): Promise<CreateAdminCommissionResponsePayload> {
    const response = await Api.post<CreateAdminCommissionResponsePayload, CreateAdminCommissionResponsePayload>(
      '/v1/admin-commission/add-private',
      payload
    );
    return response;
  }
}
