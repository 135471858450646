import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { RestrictVendorProductRequestPayload } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'RESTRICT_VENDOR_PRODUCT';

interface InitialState {
  status: HttpStatus;
  message?: string;
}

const initialState: InitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
};

export const restrictVendorProduct = createAsyncThunk(
  `${NAMESPACE}/patch`,
  async (payload: RestrictVendorProductRequestPayload) => {
    const vendorRepo = new VendorRepository();
    const vendorService = new VendorService(vendorRepo);
    const response = await vendorService.restrictVendorProduct(payload);
    return response;
  }
);

const restrictVendorProductSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetRestrictVendorProductStatus: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(restrictVendorProduct.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(restrictVendorProduct.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(restrictVendorProduct.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetRestrictVendorProductStatus } = restrictVendorProductSlice.actions;

export default restrictVendorProductSlice.reducer;
