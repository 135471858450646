import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus } from '../../../commons';
import { CreateProductPayload, CreateProductState } from '../vendor_types';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'UPLOAD_PRODUCT';

const initialState: CreateProductState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const uploadProduct = createAsyncThunk(`${NAMESPACE}/post`, async (payload: CreateProductPayload) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.uploadProduct(payload);
  return response;
});

const uploadProductSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetUploadProductStatus: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(uploadProduct.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(uploadProduct.fulfilled, (state) => {
      state.status = HttpStatus.DONE;
    });
    builder.addCase(uploadProduct.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetUploadProductStatus } = uploadProductSlice.actions;

export default uploadProductSlice.reducer;
