import { paginationFormatter } from '../../../commons/pagination_formatter';
import { PaginationPayload } from '../../../commons/types/pagination.type';
import { EditVendorT } from '../../../schema/vendors.schema';
import { Api } from '../../../utils';
import {
  CreateProductPayload,
  CreateProductResponsePayload,
  GetFilteredVendorPayload,
  GetTotalVendorsWithDatePayload,
  GetTotalVendorsWithDateResponsePayload,
  GetVendorInventoriesRequestParams,
  GetVendorInventoriesResponsePayload,
  GetVendorProductsResponsePayload,
  GetVendorsRequestParams,
  GetVendorsResponsePayload,
  IApproveVendorPayload,
  IApproveVendorResponsePayload,
  IBlockVendorPayload,
  IBlockVendorResponsePayload,
  IDeclineVendorPayload,
  IDeclineVendorResponsePayload,
  IDeleteVendorResponsePayload,
  IGetActiveVendorsResponsePayload,
  IGetPendingVendorsResponsePayload,
  IGetTotalBlockedVendorsResponsePayload,
  IGetTotalVendorsResponsePayload,
  IGetVendorDetailsPayload,
  RestrictVendorProductRequestPayload,
  RestrictVendorProductResponsePayload,
  UpdateVendorResponsePayload,
  VendorDetails,
} from '../vendor_types';

export class VendorRepository {
  async getNumberOfActiveVendors(): Promise<IGetActiveVendorsResponsePayload> {
    const response = await Api.get<IGetActiveVendorsResponsePayload, IGetActiveVendorsResponsePayload>(
      '/v1/vendor/total-approved-vendors'
    );
    return response;
  }

  async blockVendor(payload: IBlockVendorPayload): Promise<IBlockVendorResponsePayload> {
    const response = await Api.put<IBlockVendorResponsePayload, IBlockVendorResponsePayload>(
      '/v2/vendor/block-vendor',
      payload
    );
    return response;
  }

  async unBlockVendor(payload: { vendorId: string }): Promise<IBlockVendorResponsePayload> {
    const response = await Api.put<IBlockVendorResponsePayload, IBlockVendorResponsePayload>(
      '/v2/vendor/unblock-vendor',
      payload
    );
    return response;
  }

  async confirmVendor(payload: IApproveVendorPayload): Promise<IApproveVendorResponsePayload> {
    const response = await Api.put<IApproveVendorResponsePayload, IApproveVendorResponsePayload>(
      '/v1/vendor/approve',
      payload
    );
    return response;
  }

  async declineVendor(payload: IDeclineVendorPayload): Promise<IDeclineVendorResponsePayload> {
    const response = await Api.put<IDeclineVendorResponsePayload, IDeclineVendorResponsePayload>(
      '/v1/vendor/decline',
      payload
    );
    return response;
  }

  async getVendorDetails(payload: IGetVendorDetailsPayload): Promise<VendorDetails> {
    const response = await Api.get<VendorDetails, VendorDetails>(`v2/vendor/${payload.vendorId}`);
    return response;
  }

  async getTotalNumberOfBlockedVendors(): Promise<IGetTotalBlockedVendorsResponsePayload> {
    const response = await Api.get<IGetTotalBlockedVendorsResponsePayload, IGetTotalBlockedVendorsResponsePayload>(
      '/v1/vendor/total-blocked'
    );
    return response;
  }

  async getNumberOfVendors(): Promise<IGetTotalVendorsResponsePayload> {
    const response = await Api.get<IGetTotalVendorsResponsePayload, IGetTotalVendorsResponsePayload>(
      '/v1/vendor/email-verified'
    );
    return response;
  }

  async getTotalVendorsWithinDateRange(
    payload: GetTotalVendorsWithDatePayload
  ): Promise<GetTotalVendorsWithDateResponsePayload> {
    const response = await Api.get<GetTotalVendorsWithDateResponsePayload, GetTotalVendorsWithDateResponsePayload>(
      'v1/vendor/total-vendors-with-date',
      { params: payload }
    );
    return response;
  }

  async deleteVendor(payload: IGetVendorDetailsPayload): Promise<IDeleteVendorResponsePayload> {
    const response = await Api.delete<IDeleteVendorResponsePayload, IDeleteVendorResponsePayload>(
      'v1/vendor/delete-vendor',
      { params: payload }
    );
    return response;
  }

  async getFilteredVendors(payload: GetFilteredVendorPayload): Promise<IGetPendingVendorsResponsePayload> {
    const response = await Api.get<IGetPendingVendorsResponsePayload, IGetPendingVendorsResponsePayload>(
      '/v1/filter/vendor-filter',
      {
        params: payload,
      }
    );
    return response;
  }

  async getVendors({
    status,
    state,
    businessType,
    businessName,
    pageType,
    paginationCursor,
  }: GetVendorsRequestParams): Promise<GetVendorsResponsePayload> {
    const response = await Api.get<GetVendorsResponsePayload, GetVendorsResponsePayload>('/v2/vendors', {
      params: { ...paginationFormatter({ pageType, paginationCursor }), status, state, businessType, businessName },
    });
    return response;
  }

  async updateVendor(vendorId: string, payload: EditVendorT): Promise<UpdateVendorResponsePayload> {
    const response = await Api.patch<UpdateVendorResponsePayload, UpdateVendorResponsePayload>(
      `/v1/vendor/${vendorId}`,
      payload
    );
    return response;
  }

  async fetchVendorProduct(vendorId: string, arg?: PaginationPayload): Promise<GetVendorProductsResponsePayload> {
    const params = paginationFormatter(arg);
    const response = await Api.get<GetVendorProductsResponsePayload, GetVendorProductsResponsePayload>(
      `/v1/${vendorId}/products`,
      { params }
    );
    return response;
  }

  async restrictVendorProduct(
    payload: RestrictVendorProductRequestPayload
  ): Promise<RestrictVendorProductResponsePayload> {
    const { productId, status, vendorId } = payload;
    const response = await Api.patch<RestrictVendorProductResponsePayload, RestrictVendorProductResponsePayload>(
      `/v1/product/${productId}/${vendorId}`,
      { status }
    );
    return response;
  }

  async uploadProduct(payload: CreateProductPayload): Promise<CreateProductResponsePayload> {
    const response = await Api.post<CreateProductResponsePayload, CreateProductResponsePayload>(
      '/a/v1/inventory',
      payload
    );
    return response;
  }

  async getVendorInventories({
    vendorId,
    vendorType,
    pageType,
    paginationCursor,
  }: GetVendorInventoriesRequestParams): Promise<GetVendorInventoriesResponsePayload> {
    const response = await Api.get<GetVendorInventoriesResponsePayload, GetVendorInventoriesResponsePayload>(
      '/a/v1/vendor/inventories',
      {
        params: { ...paginationFormatter({ pageType, paginationCursor }), vendorId, vendorType },
      }
    );
    return response;
  }
}
