import { configureStore } from '@reduxjs/toolkit';
import ActiveVendorSliceReducer from '../../pages/vendor/slices/active_vendor_slice';
import whoAmISliceReducer from '../../redux/slices/who_am_i_slice';
import accountSliceReducer from '../../pages/accounting/slices/account_slice';
import dashboardSliceReducer from '../../pages/analytics/slices/dashboard.slice';
import orderSliceReducer from '../../pages/orders/slices/order.slice';
import riderSliceReducer from '../../pages/rider/slices/rider.slice';
import profileSliceReducer from '../../pages/settings/slices/profile.slice';
import VendorPageSliceReducer from '../../pages/vendor/slices/vendor.slice';
import verifyLoginOTPReducer from '../../pages/authentication/slices/authentication_otp_slice';
import TotalVendorSliceReducer from '../../pages/vendor/slices/total_vendor_slice';
import loginReducer from '../../pages/authentication/slices/authentication_login_slice';
import confirmVendorReducer from '../../pages/vendor/slices/confirm_vendor_slice';
import declineVendorReducer from '../../pages/vendor/slices/decline_vendor_slice';
import getTotalProductsReducer from '../slices/products/total_products_slice';
import totalRidersReducer from '../../pages/rider/slices/total_riders_slice';
import pendingRidersReducer from '../../pages/rider/slices/pending_riders_slice';
import activeRidersReducer from '../../pages/rider/slices/active_riders_slice';
import confirmRiderReducer from '../../pages/rider/slices/confirm_rider_slice';
import declineRiderReducer from '../../pages/rider/slices/decline_rider_slice';
import totalConfirmedRidersReducer from '../../pages/rider/slices/total_confirmed_slice';
import numberOfPendingOrdersReducer from '../../pages/orders/slices/number_of_pending_orders_slice';
import numberOfActiveOrdersReducer from '../../pages/orders/slices/number_of_active_orders_slice';
import numberOfDeliveredOrdersReducer from '../../pages/orders/slices/number_of_delivered_orders_slice';
import numberOfCancelledOrdersReducer from '../../pages/orders/slices/number_of_cancelled_orders_slice';
import userDetailsReducer from '../../pages/settings/slices/user_details_slice';
import getNotificationReducer from '../../pages/settings/slices/get_notification_slice';
import totalStaffsReducer from '../../pages/subAdmin/slices/total_sub_admin_slice';
import onBoardStaffReducer from '../../pages/subAdmin/slices/onboard_staff_slice';
import getAllStaffsReducer from '../../pages/subAdmin/slices/get_all_staffs_slice';
import getTotalOrdersReducer from '../../pages/orders/slices/get_total_orders_slice';
import couponSliceReducer from '../../pages/coupon/slices/tab_change_slice';
import getTotalPlacedOrdersReducer from '../../pages/orders/slices/get_total_placed_orders';
import getPendingWithdrawalReducer from '../../pages/accounting/slices/get_pending_withdrawal_slice';
import getPayoutHistoryReducer from '../../pages/accounting/slices/get_payout_history_slice';
import advertSliceReducer from '../../pages/adverts/slices/advert_slice';
import getPendingAdvertsReducer from '../../pages/adverts/slices/get_all_pending_adverts_slice';
import totalUsersReducer from '../slices/users/total_users_slice';
import fleetOwnerSliceReducer from '../../pages/fleetOwner/slice/fleet_owner_slice';
import getTotalActiveOrdersReducer from '../../pages/orders/slices/get_ongoing_order_slice';
import getCompletedOrdersReducer from '../../pages/orders/slices/get_completed_order_slice';
import blockVendorReducer from '../../pages/vendor/slices/block_vendor_slice';
import blockRiderReducer from '../../pages/rider/slices/block_rider_slice';
import totalNumberOfBlockedVendorsReducer from '../../pages/vendor/slices/total_blocked_vendors_slice';
import totalNumberOfBlockedRidersReducer from '../../pages/rider/slices/total_blocked_riders_slice';
import pendingFleetOwnersReducer from '../../pages/fleetOwner/slice/get_pending_fleet_owners_slice';
import activeFleetOwnersReducer from '../../pages/fleetOwner/slice/get_active_fleet_owners_slice';
import blockedFleetOwnersReducer from '../../pages/fleetOwner/slice/get_blocked_fleet_owners_slice';
import confirmFleetOwnerReducer from '../../pages/fleetOwner/slice/confirm_fleet_owner_slice';
import getVendorDetailsReducer from '../../pages/vendor/slices/get_vendor_details_slice';
import getRiderDetailsReducer from '../../pages/rider/slices/get_rider_details';
import blockedRidersReducer from '../../pages/rider/slices/blocked_riders_slice';
import declineFleetOwnerReducer from '../../pages/fleetOwner/slice/decline_fleet_owner_slice';
import blockFleetOwnerReducer from '../../pages/fleetOwner/slice/block_fleet_owner_slice';
import disbursePaymentReducer from '../../pages/accounting/slices/disburse_payment_slice';
import getStatesReducer from '../slices/state/get_state_slice';
import getLocalGovernmentReducer from '../slices/state/get_local_government_slice';
import getRiderByLocationReducer from '../../pages/rider/slices/get_rider_by_location_slice';
import createGeoFenceReducer from '../../pages/geoFence/slice/create_geo_fence_slice';
import totalFleetOwnersReducer from '../../pages/fleetOwner/slice/get_total_fleet_owner_slice';
import getFleetOwnerDetailsReducer from '../../pages/fleetOwner/slice/get_fleet_owner_details';
import getOrderDetailsReducer from '../../pages/orders/slices/get_order_details_slice';
import createCouponReducer from '../../pages/coupon/slices/create_coupon_slice';
import getNewCouponsReducer from '../../pages/coupon/slices/get_newly_created_coupons_slice';
import getTotalCouponReducer from '../../pages/coupon/slices/get_total_coupon';
import totalAdvertsReducer from '../../pages/adverts/slices/get_total_number_of_adverts_slice';
import getExpiredAdvertsReducer from '../../pages/adverts/slices/get_all_expired_adverts_slice';
import totalAdvertsAmountReducer from '../../pages/adverts/slices/get_total_advert_amount_slice';
import createAdminCommissionReducer from '../../pages/adminCommission/slices/add_new_commission_slice';
import getAdminCommissionReducer from '../../pages/adminCommission/slices/get_all_admin_commission_slice';
import getAdminCommissionDetailsReducer from '../../pages/adminCommission/slices/get_admin_commission_details';
import editAdminCommissionReducer from '../../pages/adminCommission/slices/edit_admin_commission_slice';
import totalNumberOfActiveFleetOwnersReducer from '../../pages/fleetOwner/slice/get_total_active_fleet_owners_slice';
import getAdminPaymentSettingReducer from '../../pages/settings/slices/get_admin_payment_setting_slice';
import getGeoFenceReducer from '../../pages/geoFence/slice/get_geo_fence_slice';
import getGeoFenceDetailsReducer from '../../pages/geoFence/slice/get_geo_fence_details';
import deleteGeoFenceReducer from '../../pages/geoFence/slice/delete_geo_fence_slice';
import addRiderToGeoFenceReducer from '../../pages/geoFence/slice/add_rider_to_geo_fence_slice';
import getVendorAdminCommissionReducer from '../../pages/adminCommission/slices/get_vendor_admin_commission';
import getRiderAdminCommissionReducer from '../../pages/adminCommission/slices/get_rider_admin_commission';
import totalOrdersPerDayReducer from '../../pages/orders/slices/get_total_orders_per_day_slice';
import deleteVendorReducer from '../../pages/vendor/slices/delete_vendor_slice';
import deleteRiderReducer from '../../pages/rider/slices/delete_rider_slice';
import totalNumberOfBlockedFleetOwnersReducer from '../../pages/fleetOwner/slice/get_total_blocked_fleet_owners_slice';
import paymentSliceReducer from '../../pages/payment/slices/payment.slice';
import addPaymentReducer from '../../pages/payment/slices/add_payment.slice';
import fetchPaymentGatewaysReducer from '../../pages/payment/slices/fetch_all_payment_gateways';
import unBlockVendorReducer from '../../pages/vendor/slices/unblock_vendor_slice';
import unblockRiderReducer from '../../pages/rider/slices/unblock_rider_slice';
import getVendorsReducer from '../../pages/vendor/slices/get_vendors_slice';
import updateVendorReducer from '../../pages/vendor/slices/update_vendor.slice';
import getVendorProductsReducer from '../../pages/vendor/slices/get_vendor_product_slice';
import restrictVendorProductReducer from '../../pages/vendor/slices/restrict_vendor_product';
import uploadProductReducer from '../../pages/vendor/slices/upload_vendor_product_slice';
import getVendorInventoriesReducer from '../../pages/vendor/slices/get_inventories_slice';

export const store = configureStore({
  reducer: {
    profilePage: profileSliceReducer,
    accountPage: accountSliceReducer,
    orderPage: orderSliceReducer,
    requestPage: VendorPageSliceReducer,
    riderPage: riderSliceReducer,
    weeklyPage: dashboardSliceReducer,
    auth: loginReducer,
    otp: verifyLoginOTPReducer,
    verifiedVendors: TotalVendorSliceReducer,
    activeVendors: ActiveVendorSliceReducer,
    whoAmI: whoAmISliceReducer,
    confirmVendor: confirmVendorReducer,
    declineVendor: declineVendorReducer,
    totalProduct: getTotalProductsReducer,
    totalRiders: totalRidersReducer,
    pendingRiders: pendingRidersReducer,
    activeRiders: activeRidersReducer,
    confirmRider: confirmRiderReducer,
    declineRider: declineRiderReducer,
    totalConfirmedRiders: totalConfirmedRidersReducer,
    numberOfPendingOrders: numberOfPendingOrdersReducer,
    numberOfActiveOrders: numberOfActiveOrdersReducer,
    numberOfDeliveredOrders: numberOfDeliveredOrdersReducer,
    numberOfCancelledOrders: numberOfCancelledOrdersReducer,
    userDetails: userDetailsReducer,
    getNotification: getNotificationReducer,
    totalSubAdmin: totalStaffsReducer,
    onBoardStaff: onBoardStaffReducer,
    getAllStaffs: getAllStaffsReducer,
    getTotalOrders: getTotalOrdersReducer,
    couponPage: couponSliceReducer,
    totalPlacedOrders: getTotalPlacedOrdersReducer,
    getPendingWithdrawal: getPendingWithdrawalReducer,
    getPayoutHistory: getPayoutHistoryReducer,
    advertPage: advertSliceReducer,
    pendingAdverts: getPendingAdvertsReducer,
    getTotalUser: totalUsersReducer,
    fleetOwner: fleetOwnerSliceReducer,
    getActiveOrders: getTotalActiveOrdersReducer,
    getCompletedOrders: getCompletedOrdersReducer,
    blockVendor: blockVendorReducer,
    unblockVendor: unBlockVendorReducer,
    blockRider: blockRiderReducer,
    totalNumberOfBlockedVendors: totalNumberOfBlockedVendorsReducer,
    totalNumberOfBlockedRiders: totalNumberOfBlockedRidersReducer,
    pendingFleetOwners: pendingFleetOwnersReducer,
    activeFleetOwners: activeFleetOwnersReducer,
    blockedFleetOwners: blockedFleetOwnersReducer,
    confirmFleetOwner: confirmFleetOwnerReducer,
    vendorDetails: getVendorDetailsReducer,
    riderDetails: getRiderDetailsReducer,
    blockedRiders: blockedRidersReducer,
    declineFleetOwner: declineFleetOwnerReducer,
    blockFleetOwner: blockFleetOwnerReducer,
    disbursePayment: disbursePaymentReducer,
    getStates: getStatesReducer,
    getLocalGovernment: getLocalGovernmentReducer,
    getRiderByLocation: getRiderByLocationReducer,
    createGeoFence: createGeoFenceReducer,
    totalFleetOwners: totalFleetOwnersReducer,
    fleetOwnerDetails: getFleetOwnerDetailsReducer,
    getOrderDetails: getOrderDetailsReducer,
    createCoupon: createCouponReducer,
    getNewCoupons: getNewCouponsReducer,
    getTotalCoupons: getTotalCouponReducer,
    totalAdvert: totalAdvertsReducer,
    getExpiredAdverts: getExpiredAdvertsReducer,
    totalAdvertsAmount: totalAdvertsAmountReducer,
    createAdminCommission: createAdminCommissionReducer,
    getAdminCommission: getAdminCommissionReducer,
    getAdminCommissionDetails: getAdminCommissionDetailsReducer,
    editAdminCommission: editAdminCommissionReducer,
    totalNumberOfActiveFleetOwners: totalNumberOfActiveFleetOwnersReducer,
    adminPaymentSetting: getAdminPaymentSettingReducer,
    getGeoFence: getGeoFenceReducer,
    getGeoFenceDetails: getGeoFenceDetailsReducer,
    deleteGeoFence: deleteGeoFenceReducer,
    addRiderToGeoFence: addRiderToGeoFenceReducer,
    getVendorAdminCommission: getVendorAdminCommissionReducer,
    getRiderAdminCommission: getRiderAdminCommissionReducer,
    totalOrdersPerDay: totalOrdersPerDayReducer,
    deleteVendor: deleteVendorReducer,
    deleteRider: deleteRiderReducer,
    totalNumberOfBlockedFleetOwners: totalNumberOfBlockedFleetOwnersReducer,
    paymentPage: paymentSliceReducer,
    addPayment: addPaymentReducer,
    fetchPaymentGateways: fetchPaymentGatewaysReducer,
    unBlockRider: unblockRiderReducer,
    getVendors: getVendorsReducer,
    updateVendor: updateVendorReducer,
    getVendorProducts: getVendorProductsReducer,
    restrictVendorProduct: restrictVendorProductReducer,
    uploadProduct: uploadProductReducer,
    getVendorInventories: getVendorInventoriesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
