import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetVendorsInitialState, GetVendorsRequestParams } from '../vendor_types';
import { HttpStatus } from '../../../commons';
import { VendorRepository } from '../repositories/vendor.repo';
import { VendorService } from '../services/vendor.service';

const NAMESPACE = 'LIST_OF_VENDORS';

const initialState: GetVendorsInitialState = {
  status: HttpStatus.IDLE,
  message: undefined,
  vendors: [],
  paginationCursor: { before: '', after: '', hasPrevious: false, hasNext: false },
};

export const getVendors = createAsyncThunk(`${NAMESPACE}/get`, async (payload: GetVendorsRequestParams) => {
  const vendorRepo = new VendorRepository();
  const vendorService = new VendorService(vendorRepo);
  const response = await vendorService.getVendors(payload);
  return response;
});

const getVendorsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getVendors.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(getVendors.fulfilled, (state, { payload }) => {
      state.status = HttpStatus.DONE;
      state.vendors = payload.vendors;
      state.paginationCursor = payload.paginationCursor;
    });
    builder.addCase(getVendors.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export default getVendorsSlice.reducer;
