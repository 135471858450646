import { extendTheme } from '@chakra-ui/react';

const dimensions = {
  xs: '0.5rem', // 8px
  sm: '0.75rem', // 12px
  md: '0.875rem', // 14px
  base: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.5rem', // 24px
  '3xl': '1.75rem', // 28px
  '4xl': '2rem', // 32px
  '5xl': '3rem', // 48px
  '6xl': '3.75rem', // 60px
  '7xl': '4.5rem', // 72px
  '8xl': '6rem', // 96px
  '9xl': '8rem', // 128px
  xxl: '10rem', // 160px
};

const theme = extendTheme({
  fonts: {
    heading: `"DM Sans", sans-serif`,
    body: `"Plus Jakarta Sans", sans-serif`,
    mono: `'Poppins', sans-serif`,
  },
  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#49494C',
    info: '#03a9f4',
    success: '#19BF0B',
    warning: '#FCBC00',
    secondary: '#FA9138',
    error: '#C20222',
    primary: '#E7375B',
    primaryLight: '#EE204A',
    accent: '#5F6368',
    darkBlue: '#272643',
    grayWhite: '#F8F8F8',
    gray100: '#979699',
    gray200: '#E1E3E6',
    gray300: '#757D8A',
    gray400: '#7C7070',
    gray500: '#E3E3E6',
    defaultText: '#7B7C7D',
    systemDark100: '#5F6368',
    systemDark300: '#48484A',
    lightGreen: '#D4EDDA',
    errorLight: '#F8D7DA',
    pending: '#4A4AFF',
    pendingLight: '#E6E6F2',
    orderSuccessBg: '#B6FAE1',
    orderSuccess: '#0DA06A',
    adminBlue: '#1877F2',
    buttonYellow: '#FAB838',
    buttonDeepBlue: '#0A2A42',
    systemGrayLight: '#F3F2F4',
    pink: {
      50: '#ffe3ec',
      100: ' #ffb6c5',
      200: ' #f8889f',
      300: ' #f35878',
      400: ' #ef2a52',
      500: ' #d51038',
      600: ' #a70a2c',
      700: ' #77041e',
      800: ' #4a0012',
      900: ' #1f0004',
    },
    orange: {
      100: '#FB861E',
    },
  },
  fontSizes: dimensions,
  space: dimensions,
});

export default theme;
