import { Api } from '../../../utils';
import { IVerifyOTPPayload, IVerifyOTPResponsePayload, LoginResponsePayload } from '../auth_types';
import { LoginFormPayload } from '../../../schema/authentication.schema';

export class AuthenticationRepository {
  async login(payload: LoginFormPayload): Promise<LoginResponsePayload> {
    const response = await Api.post<LoginResponsePayload, LoginResponsePayload>('/v1/admin/login', payload);
    window.sessionStorage.setItem('userEmail', payload.email);
    return response;
  }

  async verifyLoginOTP(otp: string): Promise<IVerifyOTPResponsePayload> {
    const email = window.sessionStorage.getItem('userEmail') as string;
    const payload: IVerifyOTPPayload = { email, otp };
    const response = await Api.post<IVerifyOTPResponsePayload, IVerifyOTPResponsePayload>(
      '/v1/admin/verify-otp',
      payload
    );
    return response;
  }
}
