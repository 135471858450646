import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatus, setCredentials } from '../../../commons';
import { LoginState } from '../auth_types';
import { AuthenticationRepository } from '../repositories/authentication.repo';
import { AuthenticationService } from '../services/authentication.service';
import { LoginFormPayload } from '../../../schema/authentication.schema';

const NAMESPACE = 'ADMIN_LOGIN';

const initialState: LoginState = {
  status: HttpStatus.IDLE,
  message: undefined,
  response: undefined,
};

export const login = createAsyncThunk(`${NAMESPACE}/post`, async (payload: LoginFormPayload) => {
  const authenticationRepo = new AuthenticationRepository();
  const authenticationService = new AuthenticationService(authenticationRepo);
  const response = await authenticationService.login(payload);
  return response;
});

const loginSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.status = HttpStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.status = HttpStatus.LOADING;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      setCredentials(payload.token, payload.adminId, payload.role);
      state.status = HttpStatus.DONE;
    });
    builder.addCase(login.rejected, (state, { error }) => {
      state.status = HttpStatus.ERROR;
      state.message = error?.message ?? String(error);
    });
  },
});

export const { resetAuthState } = loginSlice.actions;

export default loginSlice.reducer;
