import { AuthenticationRepository } from '../repositories/authentication.repo';
import { LoginFormPayload } from '../../../schema/authentication.schema';
import { IVerifyOTPResponsePayload, LoginResponsePayload } from '../auth_types';

export class AuthenticationService {
  constructor(private readonly authenticationRepo: AuthenticationRepository) {}

  async login(payload: LoginFormPayload): Promise<LoginResponsePayload> {
    const response = await this.authenticationRepo.login(payload);
    return response;
  }

  async verifyLoginOTP(payload: string): Promise<IVerifyOTPResponsePayload> {
    const response = await this.authenticationRepo.verifyLoginOTP(payload);
    return response;
  }
}
